import React, { useState } from 'react';
import { Button, Row, Col, Card, Typography, Avatar, Modal } from 'antd';
import { StarFilled, RightOutlined, CheckCircleFilled, CloudServerOutlined, SafetyCertificateOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import './HomePage.css';
import bannerImage from '../assets/images/logo_minhdev.jpeg';
import template1 from '../assets/images/template-1.jpg';
import template2 from '../assets/images/template-2.jpg';
import template3 from '../assets/images/template-3.jpg';
import template4 from '../assets/images/template-4.jpg';
import template5 from '../assets/images/template-5.jpg';
import template6 from '../assets/images/template-6.jpg';

const { Title, Paragraph, Text } = Typography;

const templates = [
  { id: 1, image: template1, title: 'Website Doanh Nghiệp 1' },
  { id: 2, image: template2, title: 'Website Doanh Nghiệp 2' },
  { id: 3, image: template3, title: 'Website Doanh Nghiệp 3' },
  { id: 4, image: template4, title: 'Website Doanh Nghiệp 4' },
  { id: 5, image: template5, title: 'Website Doanh Nghiệp 5' },
  { id: 6, image: template6, title: 'Website Doanh Nghiệp 6' },
  // ... thêm các template khác
];

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  return (
    <>
      {/* Banner Section */}
      <div className="banner">
        <div className="container">
          <Row gutter={[48, 48]} align="middle">
            <Col lg={12} xs={24}>
              <div className="banner-content">
                <Title className="banner-title">
                  Thiết Kế Website 
                  <span className="highlight">Chuyên Nghiệp</span>
                </Title>
                <Paragraph className="banner-desc">
                  Giải pháp thiết kế website tối ưu với hơn 1000+ mẫu giao diện đẹp, 
                  tích hợp đầy đủ tính năng cho doanh nghiệp của bạn
                </Paragraph>
                <div className="banner-features">
                  <ul>
                    <li><CheckCircleFilled /> Miễn phí hosting tốc độ cao</li>
                    <li><CheckCircleFilled /> Tặng tên miền .COM trị giá 360k</li>
                    <li><CheckCircleFilled /> Bảo hành website trọn đời</li>
                    <li><CheckCircleFilled /> Hỗ trợ SEO Google tối ưu</li>
                  </ul>
                </div>
                <div className="banner-buttons">
                  <Button type="primary" size="large" className="btn-gradient">
                    Dùng thử 15 ngày miễn phí
                  </Button>
                  <Button size="large" className="btn-outline">
                    Xem bảng giá <RightOutlined />
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="banner-image-wrapper">
                <img src={bannerImage} alt="Website Design" className="banner-image" />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Stats Section */}
      <div className="stats-section">
        <div className="container">
          <Row justify="space-between" gutter={[32, 32]}>
            {[
              { number: "150,000+", label: "Khách hàng tin dùng" },
              { number: "5,000+", label: "Website đã triển khai" },
              { number: "200+", label: "Chuyên gia tư vấn" },
              { number: "24/7", label: "Hỗ trợ khách hàng" }
            ].map((stat, index) => (
              <Col key={index} xs={12} md={6}>
                <div className="stat-item">
                  <div className="stat-number">{stat.number}</div>
                  <div className="stat-label">{stat.label}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      {/* Templates Showcase */}
      <section className="templates-section">
        <Title level={2} className="section-title">Mẫu Website Đẹp, Chuyên Nghiệp</Title>
        <Row gutter={[24, 24]}>
          {templates.map((item) => (
            <Col xs={24} md={8} key={item.id}>
              <Card 
                hoverable 
                className="template-card"
                cover={<img alt={item.title} src={item.image} />}
              >
                <Title level={4}>{item.title}</Title>
                <Button type="link">Xem chi tiết <RightOutlined /></Button>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      {/* Testimonials */}
      <section className="testimonials-section">
        <div className="container">
          <Title level={2} className="section-title text-center">
            Đánh Giá Từ Khách Hàng
          </Title>
          <Row gutter={[32, 32]} className="testimonials-grid">
            {[1, 2, 3].map((item) => (
              <Col xs={24} md={8} key={item}>
                <Card className="testimonial-card" bordered={false}>
                  <div className="testimonial-rating">
                    {[1,2,3,4,5].map(star => (
                      <StarFilled key={star} className="star-icon" />
                    ))}
                  </div>
                  <Paragraph className="testimonial-quote">
                    "Dịch vụ thiết kế website chuyên nghiệp, tư vấn tận tình. Giao diện đẹp và hiện đại, tối ưu cho kinh doanh."
                  </Paragraph>
                  <div className="testimonial-profile">
                    <Avatar 
                      size={64}
                      src={`/avatars/client-${item}.jpg`}
                      alt="Khách hàng"
                    />
                    <div className="testimonial-info">
                      <Title level={5}>Nguyễn Thành Công</Title>
                      <Text type="secondary">CEO Công ty {item}</Text>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      <section className="cta-section">
        <div className="container">
          <Row justify="center" align="middle" className="cta-wrapper">
            <Col xs={24} md={16} className="text-center">
              <Title level={2} className="cta-title">
                Bắt Đầu Xây Dựng Website Của Bạn
              </Title>
              <Paragraph className="cta-description">
                Đội ngũ chuyên gia của chúng tôi sẽ tư vấn giải pháp tốt nhất cho doanh nghiệp của bạn
              </Paragraph>
              <Button type="primary" size="large" className="cta-button" onClick={showModal}>
                Nhận Tư Vấn Miễn Phí
              </Button>
            </Col>
          </Row>
        </div>
      </section>

      <section className="benefits-section">
        <div className="container">
          <Title level={2} className="section-title text-center">
            Tại Sao Chọn Chúng Tôi?
          </Title>
          <Row gutter={[32, 32]}>
            {[
              {
                icon: <CloudServerOutlined className="benefit-icon" />,
                title: "Hosting Hiệu Năng Cao",
                desc: "Sử dụng công nghệ SSD mới nhất, đảm bảo website luôn hoạt động ổn định 24/7"
              },
              {
                icon: <SafetyCertificateOutlined className="benefit-icon" />,
                title: "Bảo Mật Tối Đa",
                desc: "SSL/HTTPS miễn phí, bảo vệ dữ liệu và thông tin khách hàng an toàn"
              },
              {
                icon: <RightOutlined className="benefit-icon" />,
                title: "Hỗ Trợ Kỹ Thuật",
                desc: "Đội ngũ kỹ thuật chuyên nghiệp hỗ trợ 24/7"
              }
            ].map((benefit, index) => (
              <Col xs={24} md={8} key={index}>
                <Card className="benefit-card" bordered={false}>
                  <div className="benefit-icon-wrapper">
                    {benefit.icon}
                  </div>
                  <Title level={4} className="benefit-title">
                    {benefit.title}
                  </Title>
                  <Paragraph className="benefit-desc">
                    {benefit.desc}
                  </Paragraph>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      <Modal 
        title="Thông Tin Liên Hệ" 
        open={isModalOpen} 
        onCancel={handleCancel}
        footer={null}
        className="contact-modal"
      >
        <div className="contact-info">
          <p><PhoneOutlined /> Điện thoại: <a href="tel:0362530393">0362530393</a></p>
          <p><WhatsAppOutlined /> Zalo: <a href="https://zalo.me/0362530393">0362530393</a></p>
        </div>
      </Modal>
    </>
  );
};

export default HomePage;

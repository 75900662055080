import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js'
import HomePage from './pages/HomePage.js';
import AboutsPage from './pages/AboutsPage.js'
import RelaxPage from './pages/RelaxPage.js'
import ToolsPage from './pages/ToolsPage.js';
import WorkspacePage from './pages/WorkspacePage.js';
import Footer from './components/Footer.js';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/abouts" element={<AboutsPage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/relax" element={<RelaxPage />} />
          <Route path="/workspace" element={<WorkspacePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

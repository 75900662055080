import React from 'react';
import { Row, Col, Typography } from 'antd';
import { 
  PhoneOutlined, 
  MailOutlined, 
  EnvironmentOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  GithubOutlined
} from '@ant-design/icons';
import './Footer.css';

const { Title } = Typography;

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <Row gutter={[32, 32]}>
          {/* Về chúng tôi */}
          <Col xs={24} sm={12} md={6}>
            <div className="footer-section">
              <Title level={4} className="footer-title">Về chúng tôi</Title>
              <ul className="footer-list">
                <li><a href="/about">Giới thiệu</a></li>
                <li><a href="/portfolio">Dự án</a></li>
                <li><a href="/blog">Blog</a></li>
                <li><a href="/careers">Tuyển dụng</a></li>
              </ul>
            </div>
          </Col>

          {/* Dịch vụ */}
          <Col xs={24} sm={12} md={6}>
            <div className="footer-section">
              <Title level={4} className="footer-title">Dịch vụ</Title>
              <ul className="footer-list">
                <li><a href="/services/web-design">Thiết kế website</a></li>
                <li><a href="/services/web-development">Phát triển web</a></li>
                <li><a href="/services/mobile-app">Ứng dụng di động</a></li>
                <li><a href="/services/seo">Dịch vụ SEO</a></li>
              </ul>
            </div>
          </Col>

          {/* Hỗ trợ */}
          <Col xs={24} sm={12} md={6}>
            <div className="footer-section">
              <Title level={4} className="footer-title">Hỗ trợ</Title>
              <ul className="footer-list">
                <li><a href="/support">Trung tâm hỗ trợ</a></li>
                <li><a href="/faq">Câu hỏi thường gặp</a></li>
                <li><a href="/contact">Liên hệ</a></li>
                <li><a href="/terms">Điều khoản sử dụng</a></li>
              </ul>
            </div>
          </Col>

          {/* Liên hệ */}
          <Col xs={24} sm={12} md={6}>
            <div className="footer-section">
              <Title level={4} className="footer-title">Liên hệ</Title>
              <div className="contact-info">
                <div className="contact-item">
                  <PhoneOutlined /> 0362530393
                </div>
                <div className="contact-item">
                  <MailOutlined /> nminh.dev@gmail.com
                </div>
                <div className="contact-item">
                  <EnvironmentOutlined /> TP.HCM, Việt Nam
                </div>
                <div className="social-links">
                  <a href="https://www.facebook.com/nminhdev" target="_blank" rel="noopener noreferrer">
                    <FacebookOutlined />
                  </a>
                  <a href="https://www.linkedin.com/in/nminhdev/" target="_blank" rel="noopener noreferrer">
                    <LinkedinOutlined />
                  </a>
                  <a href="https://github.com/nguyenminh-dev" target="_blank" rel="noopener noreferrer">
                    <GithubOutlined />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} MinhDev. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

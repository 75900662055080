import React from 'react';
import { Layout, Row, Col, Avatar, Typography } from 'antd';
import './AboutsPage.css';
import chplay from '../assets/images/chplay.jpg';
import appstore from '../assets/images/appstore.jpg';
import avatar from '../assets/images/logo.jpg';

const { Title, Paragraph } = Typography;

const calculateWorkDuration = (startDate) => {
  const start = new Date(startDate);
  const now = new Date();
  const diff = now - start;
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
  return `${years} năm ${months} tháng`;
};

const AboutsPage = () => {
  const workDuration = calculateWorkDuration('2021-10-01');

  return (
    <Layout className="aboutpage">
      {/* Profile Section */}
      <div className="profile-section">
        <Avatar size={120} src={avatar} />
        <Title level={3}>Nguyễn Văn Minh</Title>
        <Paragraph strong>Kỹ sư phần mềm</Paragraph>
      </div>

      {/* About Section */}
      <div className="about-section">
        <Row justify="center">
          <Col span={16}>
            <Paragraph>
              Tôi là một <strong>Developer</strong> với nền tảng vững chắc về kiến trúc <strong>microservices</strong>, 
              đặc biệt trong hệ sinh thái <strong>.NET</strong>. Tôi sử dụng <strong>ABP Framework</strong> để tối ưu hóa phát triển, 
              kết hợp với <strong>Azure DevOps</strong> và <strong>Kubernetes</strong> để tự động hóa CI/CD.
            </Paragraph>
            <Paragraph>
              Có kinh nghiệm với <strong>PostgreSQL</strong>, <strong>SQL Server</strong>, tôi tối ưu hóa truy vấn 
              và thiết kế kiến trúc dữ liệu cho các hệ thống có tải cao.
            </Paragraph>
          </Col>
        </Row>
      </div>

      {/* Education Section */}
      <div className="education-section">
        <Row justify="center">
          <Col span={16}>
            <Title level={3}>Trình độ học vấn</Title>
            <Paragraph>
              <strong>Trường đại học Bách Khoa - Đại học Quốc gia TP.HCM</strong>
              Chuyên ngành: Khoa học máy tính
            </Paragraph>
          </Col>
        </Row>
      </div>

      {/* Work Experience Section */}
      <div className="work-section">
        <Row justify="center">
          <Col span={16}>
            <Title level={3}>Kinh nghiệm làm việc</Title>
            <Title level={4}>Công ty Công nghệ Trường Minh Thịnh</Title>
            <Paragraph><strong>Chức vụ:</strong> Nhân viên lập trình</Paragraph>
            <Paragraph><strong>Thời gian làm việc:</strong> {workDuration}</Paragraph>
          </Col>
        </Row>
      </div>

      {/* Download Section */}
      <div className="download-section">
        <Title level={5}>Ứng dụng WiOnPos:</Title>
        <Paragraph>Phần mềm quản lý bán hàng cho cá nhân và doanh nghiệp</Paragraph>
        <Row gutter={16} justify="center">
          <Col>
            <a href="https://play.google.com/store/apps/details?id=asia.tmtco.wion.pos&hl=vi" 
               target="_blank" 
               rel="noopener noreferrer">
              <img src={chplay} alt="Google Play" style={{ width: '150px' }} />
            </a>
          </Col>
          <Col>
            <a href="https://apps.apple.com/vn/app/wion-pos/id6468011285" 
               target="_blank" 
               rel="noopener noreferrer">
              <img src={appstore} alt="App Store" style={{ width: '150px' }} />
            </a>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default AboutsPage;
